import React, { useEffect } from "react";
import { Box, Typography, Grid, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import dynamicStyle from "./styles";
import LocalStorage from "../../managers/LocalStorage";

const Forbidden = () => {
  const classes = dynamicStyle();
  const navigate = useNavigate();
  const user = LocalStorage.getUser();

  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, []);

  return (
    <Box className={classes.box}>
      <Container maxWidth="md">
        <Grid container spacing={2}>
          <Grid xs={6}>
            <Typography variant="h1">403</Typography>
            <Typography variant="h6">
              You don't have permission to access this page.
            </Typography>
          </Grid>
          <Grid xs={6}>
            <img
              src="https://cdni.iconscout.com/illustration/premium/thumb/error-403-4190274-3468591.png"
              alt=""
              width={500}
              // height={250}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Forbidden;
