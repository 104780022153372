import { SING_IN } from "../ApiConstants";
import { api } from "../../utils";

class AuthApi {
  static sharedIstance = new AuthApi();

  constructor() {
    if (AuthApi.sharedIstance != null) {
      return AuthApi.sharedIstance;
    }
  }

  //   SignIN User  ...
  async singIn(body) {
    try {
      const response = await api.post(SING_IN, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
}

export default AuthApi.sharedIstance;
