import * as yup from "yup";

const signInValidation = {
  userName: "User name is required",
  email: "Email is required",
  password: "Password is required",
  validEmail: "Provide a valid email",
};

export const signInValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required(signInValidation.email)
    .email(signInValidation.validEmail),
  password: yup.string().required(signInValidation.password),
});

const storeValidation = {
  storeName: "Store name is required",
};

export const storeValidationSchema = yup.object().shape({
  storeName: yup.string().required(storeValidation.storeName),
});

const locationValidation = {
  locationName: "Location is required",
  phoneNo: "Phone no is required",
};

export const locationValidationSchema = yup.object().shape({
  locationName: yup.string().required(locationValidation.locationName),
  phoneNo: yup
    .array()
    .of(yup.string().required(locationValidation.phoneNo))
    .min(1, "At leaset on phone number  is required"),
});
