import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { styled } from "@mui/system";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import RoomIcon from "@mui/icons-material/Room";
import StoreIcon from "@mui/icons-material/Store";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { IconButton, ListItemIcon } from "@mui/material";

import { useAuth } from "../../hooks/useAuth";

// Create styled components for the sidebar and top bar
const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const SideBar = ({
  open,
  handleDrawerClose,
  theme,
  setOpen,
  isSmallScreen,
}) => {
  const { isLoggedIn, user } = useAuth();
  const location = useLocation();

  // Return null if user is not logged in
  if (!isLoggedIn) {
    return null;
  }

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleListItemClick = () => {
    if (isSmallScreen) {
      setOpen(false);
    }
  };

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            marginTop: "64px",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <div onClick={handleListItemClick}>
          <List>
            {/* Admin Routes access */}
            <ListItem
              button
              component={Link}
              to="/dashboard"
              selected={isActive("/dashboard")}
            >
              <ListItemIcon style={{ display: "flex-end" }}>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
            {/* Stores */}
            <ListItem
              button
              component={Link}
              to="/stores"
              selected={isActive("/stores")}
            >
              <ListItemIcon>
                <StoreIcon />
              </ListItemIcon>
              <ListItemText primary="Stores" />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </>
  );
};

export default SideBar;
