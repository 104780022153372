import React from "react";
import { CircularProgress, Typography, Box } from "@mui/material";

class SemiCircleProgress extends React.Component {
  render() {
    const { value, delta, size, label, maxValue = 5 } = this.props;
    const normalizedValue = (value / maxValue) * 100;

    // Add a wrapper Box for alignment
    return (
      <Box textAlign="center" style={{ margin: "10px" }}>
        {/* Adjust the margin as needed */}
        <Typography variant="h6" component="div" color="textPrimary">
          {label}
        </Typography>
        <Box
          position="relative"
          display="inline-flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress
            variant="determinate"
            value={100}
            size={size}
            thickness={4}
            style={{ color: "#e6ffe7" }} // Set this to the grey color you want
          />
          <CircularProgress
            variant="determinate"
            value={normalizedValue} // To create the effect of a half circle, the value is always 50
            size={size}
            thickness={5}
            style={{
              color: "#4CAF50",
              position: "absolute", // Position over the grey circle
              top: 0,
            }} // Hide the track
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography
              variant="h4"
              component="div"
              color="textPrimary"
              style={{ fontSize: 18, fontWeight: "bold" }}
            >
              {value}
            </Typography>
            {delta !== "" && (
              <Typography
                variant="subtitle1"
                component="div"
                style={{ fontSize: 12, color: delta >= 0 ? "green" : "red" }}
              >
                {delta > 0 ? `+${delta}` : delta}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    );
  }
}

export default SemiCircleProgress;
