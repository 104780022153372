import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

import { storeValidationSchema } from "../../../../utils/validations";
import StoreApi from "../../../../services/Apis/Store.Api";

import dynamicStyle from "./styles";

const initialValues = {
  storeName: "",
};

const DialogForm = (props) => {
  const { open, setOpen, refetchTags, storeID, setStoreID } = props;

  const classes = dynamicStyle();

  const { data: singleStore } = useQuery(
    ["GET_SINGLE_STORE", storeID],
    () => StoreApi.getSingleStore(storeID),
    {
      enabled: !!storeID,
    }
  );

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: storeValidationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  // Set form fields if update store ..
  useEffect(() => {
    if (singleStore != undefined && storeID != null) {
      formik.setFieldValue("storeName", singleStore[0]?.name);
    }
  }, [singleStore]);

  //  Create Tag   ...
  const { mutate: Store, isLoading } = useMutation(
    (body) => {
      if (storeID !== null) {
        return StoreApi.updateStore(body, storeID);
      } else {
        return StoreApi.createStore(body);
      }
    },
    {
      onSuccess: (res) => {
        if (res.success) {
          toast.success(res.message);
          refetchTags();
          handleClose();
          formik.resetForm();
        } else {
          toast.error(res.message);
        }
      },
      onError: (error) => toast.error(error.message),
    }
  );

  const handleSubmit = (values) => {
    Store(values);
  };

  const handleClose = () => {
    setStoreID(null);
    setOpen(false);
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "430px",
          },
        },
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        {storeID != null ? "Update Store" : "Add new Store"}
      </DialogTitle>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        <DialogContent className={classes.dialogContent}>
          <TextField
            autoFocus
            type="text"
            margin="dense"
            name="storeName"
            label="Store Name"
            value={formik.values.storeName}
            onChange={formik.handleChange}
            error={formik.touched.storeName && Boolean(formik.errors.storeName)}
            helperText={formik.touched.storeName && formik.errors.storeName}
            fullWidth
            size="small"
          />
          <DialogActions>
            <Button
              className={classes.deleteButton}
              style={{ backgroundColor: "#ed342a", color: "white" }}
              onClick={handleClose}
              variant="contained"
            >
              <CloseIcon fontSize="small" className={classes.iconsStyle} />{" "}
              Cancel
            </Button>
            <Button variant="contained" type="submit">
              <SaveIcon fontSize="small" className={classes.iconsStyle} /> Save
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default DialogForm;
