import React from "react";
import {
  Grid,
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  CircularProgress,
  Divider,
} from "@mui/material";
import { toast } from "react-toastify";
import { makeStyles } from "@mui/styles";
import moment from "moment";

import StoreApi from "../../services/Apis/Store.Api";
import SemiCircleProgress from "../../components/Chart";
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    // Get the current month index and convert it to the month string
    const currentMonthIndex = new Date().getMonth();
    const currentMonth = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ][currentMonthIndex];

    this.state = {
      storesList: [],
      selectedStore: {},
      selectedMonth: currentMonth, // Set the current month as default
      sales: "",
      salesChange: "",
      service: "",
      serviceChange: "",
      csi: "",
    };
  }

  componentDidMount() {
    this.fetchStores();
  }

  fetchStores = async () => {
    try {
      console.log("get stores");
      const response = await StoreApi.getStores();
      console.log("get stores", response);
      this.setState({ storesList: response, selectedStore: response[0] });
    } catch (error) {
      console.error("Error fetching stores", error);
    }
  };

  handleStoreChange = (event, value) => {
    this.setState({
      selectedStore: value,
      sales: "",
      salesChange: "",
      service: "",
      serviceChange: "",
      csi: "",
    });
  };

  handleMonthChange = (event) => {
    this.setState({ selectedMonth: event.target.value });
  };

  handleInputChange = (event) => {
    let isKia =
      this.state.selectedStore?.name?.toLowerCase()?.includes("kia") ?? false;
    if (isKia && event.target.value > 100)
      return alert("maximum input can be 100");
    if (
      !isKia &&
      (event.target.name == "sales" || event.target.name == "service") &&
      event.target.value > 5
    )
      return alert("maximum input can be 5");
    if (
      !isKia &&
      (event.target.name == "sales" || event.target.name == "service") &&
      event.target.value < 0
    )
      return alert("minimum input can be 0.1");
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSave = async () => {
    try {
      let isKia =
        this.state.selectedStore?.name?.toLowerCase()?.includes("kia") ?? false;

      const { sales, salesChange, serviceChange, service, csi } = this.state;
      let storeID = this.state.selectedStore.ID;
      let body = {
        sales,
        salesChange,
        service,
        serviceChange,
        csi,
        storeID,
      };

      if (!storeID) return alert("Store cannot be empty");
      if (isKia) {
        if (sales == "") return alert("Sales Index cannot be empty");
        if (service == "") return alert("Service Index cannot be empty");
        if (csi == "") return alert("CSI cannot be empty");
      } else {
        if (sales == "") return alert("Sales cannot be empty");
        if (service == "") return alert("Service cannot be empty");
        if (salesChange == "") return alert("Sales Change cannot be empty");
        if (serviceChange == "") return alert("Service Change cannot be empty");
      }
      this.setState({ saving: true });
      const response = await StoreApi.updateStoreScore(body, storeID);
      console.log("update stores score", response);
      toast.success("Saved Successfully");

      this.setState({
        saving: false,
        sales: "",
        salesChange: "",
        service: "",
        serviceChange: "",
        csi: "",
      });
    } catch (error) {
      this.setState({ saving: false });

      console.error("Error updating stores", error);
    }
  };

  render() {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let isKia =
      this.state.selectedStore?.name?.toLowerCase()?.includes("kia") ?? false;

    return (
      <>
        <Grid
          container
          spacing={2}
          style={{ padding: 20 }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={"auto"}>
            <Autocomplete
              options={this.state.storesList}
              getOptionLabel={(option) => option.name} // Use the property that holds the name of the store
              style={{ minWidth: 250 }}
              onChange={this.handleStoreChange}
              value={this.state.selectedStore} // Use the selectedStore as the value for the Autocomplete
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label="Select Store"
                  variant="outlined"
                  helperText={`Last Update: ${moment(
                    this.state.selectedStore.lastUpdate
                  ).format("YYYY-MM-DD")}`}
                />
              )}
            />
          </Grid>
          {/* <Grid item xs={4} sm={"auto"}>
            <FormControl fullWidth variant="outlined" style={{ minWidth: 120 }}>
              <InputLabel>Month</InputLabel>
              <Select
                fullWidth
                value={this.state.selectedMonth}
                onChange={this.handleMonthChange}
                label="Month"
              >
                {months.map((month, index) => (
                  <MenuItem key={index} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid> */}
        </Grid>
        <Grid
          container
          spacing={2}
          style={{ padding: 20 }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={8} sm={2}>
            <TextField
              fullWidth
              label={isKia ? "SSI (Sales Index)" : "Sales"}
              type="number"
              name="sales"
              value={this.state.sales}
              onChange={this.handleInputChange}
              variant="outlined"
            />
          </Grid>
          {!isKia && (
            <Grid item xs={4} sm={2}>
              <TextField
                fullWidth
                label="Sales Change"
                type="number"
                name="salesChange"
                value={this.state.salesChange}
                onChange={this.handleInputChange}
                variant="outlined"
              />
            </Grid>
          )}
          <Grid item xs={8} sm={2}>
            <TextField
              fullWidth
              label={isKia ? "ASSI (Service Index)" : "Service"}
              type="number"
              name="service"
              value={this.state.service}
              onChange={this.handleInputChange}
              variant="outlined"
            />
          </Grid>
          {!isKia && (
            <Grid item xs={4} sm={2}>
              <TextField
                fullWidth
                label="Service Change"
                type="number"
                name="serviceChange"
                value={this.state.serviceChange}
                onChange={this.handleInputChange}
                variant="outlined"
              />
            </Grid>
          )}
          {isKia && (
            <Grid item xs={12} sm={2}>
              <TextField
                fullWidth
                label="CSI (Combined Index)"
                type="number"
                name="csi"
                value={this.state.csi}
                onChange={this.handleInputChange}
                variant="outlined"
              />
            </Grid>
          )}
        </Grid>
        <Grid
          container
          justifyContent={"center"}
          alignItems={"center"}
          sx={{ my: 4 }}
        >
          <SemiCircleProgress
            label={isKia ? "SSI (Sales Index)" : "Sales"}
            size={75}
            maxValue={isKia ? 100 : 5}
            value={this.state.sales}
            delta={this.state.salesChange}
          />
          <SemiCircleProgress
            label={isKia ? "ASSI (Service Index)" : "Service"}
            size={75}
            maxValue={isKia ? 100 : 5}
            value={this.state.service}
            delta={this.state.serviceChange}
          />
          {isKia && (
            <SemiCircleProgress
              label="CSI (Combined Index)"
              size={75}
              maxValue={isKia ? 100 : 5}
              value={this.state.csi}
              delta={""}
            />
          )}
        </Grid>
        <Grid
          container
          spacing={2}
          style={{ padding: 20 }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={3}>
            {this.state.saving ? (
              <CircularProgress />
            ) : (
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.handleSave}
              >
                Save
              </Button>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}

export default Dashboard;
