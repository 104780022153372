import axios from "axios";
import LocalStorage from "../../managers/LocalStorage";

const api = axios.create({
  baseURL: "https://cei.camclarkgroup.com/api/",
});

// auth header globally ..

api.interceptors.request.use(function (config) {
  try {
    let token = LocalStorage.getToken();
    config.headers.Authorization = `bearer ${token}`;
    return config;
  } catch (error) {
    throw new Error(error.messaage);
  }
});

export default api;
