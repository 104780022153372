import React from "react";
import { Grid, Button } from "@mui/material";
import { useQuery } from "react-query";
import StoreApi from "../../services/Apis/Store.Api";

import { StoreList } from "./components";

const Stores = () => {
  const { data: storesList, refetch: refetchStores } = useQuery(
    "GET_ALL_STORES",
    () => StoreApi.getStores()
  );

  return (
    <Grid>
      <StoreList data={storesList} refetch={refetchStores} />
    </Grid>
  );
};

export default Stores;
