import {
  AppBar,
  Button,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext, useAuth } from "../../hooks/useAuth";
import LocalStorage from "../../managers/LocalStorage";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import MenuIcon from "@mui/icons-material/Menu";

import dynamicStyle from "./styles";

const Navbar = ({ open, handleDrawerOpen, setOpen }) => {
  const classes = dynamicStyle();

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParam] = useSearchParams();
  const store = searchParam.get("store");

  const { isLoggedIn, user, signout } = useAuth();

  const handleLogout = () => {
    signout();
    setOpen(false);
    toast.success("Logout successfully!", { autoClose: 2000 });
    navigate("/");
  };

  return (
    <div className={classes.root}>
      <Toolbar sx={{ zIndex: 1 }}>
        {isLoggedIn && (
          <>
            {open ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
              >
                <ChevronLeftIcon />
              </IconButton>
            ) : (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            )}
          </>
        )}

        <Typography
          variant="h6"
          component="div"
          sx={{ flexGrow: 1, textTransform: "uppercase" }}
        >
          <Link to="/" style={{ textDecoration: "none", color: "white" }}>
            CEI Scores
          </Link>
        </Typography>
        {isLoggedIn && (
          <>
            <Grid className={classes.Username}>
              <Typography>{`${user?.name}`}</Typography>
            </Grid>

            <Button variant="contained" onClick={handleLogout}>
              Logout
            </Button>
          </>
        )}
      </Toolbar>
    </div>
  );
};

export default Navbar;
