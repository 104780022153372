export const SING_IN = "user/login";

// Store apis constants  ..
export const GET_STORES = "stores/allstores";
export const CREATE_STORE = "stores/create";
export const GET_SINGLE_STORE = "stores/";
export const DELETE_STORE = "stores/delete";
export const UPDATE_STORE = "stores/update";

// Location apis constants ..
export const GET_LOCATIONS_BY_STORE = "location/locationbystore/";
export const GET_SINGLE_LOCATION = "location";
export const CREATE_LOCATION = "location/create";
export const DELETE_LOCATION = "location/delete";
export const UPDATE_LOCATION = "location/update";

// Complain api  ..
export const CREATE_COMPLAIN = "complain/create";
export const GET_COMPLAINS = "complain/allcomplains";

// Dashboard apis ..
export const GET_TOTAL_COMPLAINS = "dashboard/complains";
export const GET_STORES_COMPLAINS = "dashboard/complainsbystores";
export const GET_WEEKLY_COMPLAINS = "dashboard/weeklycomplains";
