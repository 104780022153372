import React from "react";
import { Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import StoreApi from "../../services/Apis/Store.Api";
import SemiCircleProgress from "../../components/Chart";
function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} navigate={navigate} params={params} />;
  }

  return ComponentWithRouterProp;
}
class ChartView extends React.Component {
  constructor(props) {
    super(props);
    // Get the current month index and convert it to the month string
    const currentMonthIndex = new Date().getMonth();
    const currentMonth = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ][currentMonthIndex];

    this.state = {
      storesList: [],
      selectedStore: {},
      selectedMonth: currentMonth, // Set the current month as default
      sales: "",
      salesChange: "",
      service: "",
      serviceChange: "",
      csi: "",
    };
  }

  componentDidMount() {
    this.fetchStores();
  }

  fetchStores = async () => {
    try {
      console.log("get stores");
      const response = await StoreApi.getStores();
      console.log("get stores", response);
      console.log("this.props", this.props);
      const store_id = this.props.params.store_id; // Accessing the store_id from params
      console.log("store_id");
      console.log(store_id);
      if (store_id) {
        const selectedStore = response.find(
          (store) => store.ID.toString() === store_id
        );
        if (selectedStore) {
          console.log("selectedStore", selectedStore);
          this.setState({ storesList: response, selectedStore });
        } else {
          // If no matching store is found, redirect to /forbidden
          this.props.navigate("/forbidden");
        }
      } else {
        // If no store_id param is provided, redirect to /forbidden
        this.props.navigate("/forbidden");
      }
    } catch (error) {
      console.error("Error fetching stores", error);
      this.props.navigate("/forbidden");
    }
  };

  render() {
    let isKia =
      this.state.selectedStore?.name?.toLowerCase()?.includes("kia") ?? false;

    return (
      <>
        <h2
          style={{ textAlign: "center", padding: 0, margin: 0, fontSize: 20 }}
        >
          {moment(this.state.selectedStore.lastUpdate)
            .subtract(1, "month")
            .format("MMMM")}{" "}
          {isKia ? "Scores" : "CEI"}
        </h2>
        <Grid
          style={{ marginTop: -12 }}
          container
          justifyContent={"center"}
          alignItems={"center"}
        >
          <SemiCircleProgress
            label={isKia ? "SSI" : "Sales"}
            size={75}
            maxValue={isKia ? 100 : 5}
            value={this.state.selectedStore.sales}
            delta={this.state.selectedStore.salesChange}
          />
          <SemiCircleProgress
            label={isKia ? "ASSI" : "Service"}
            size={75}
            maxValue={isKia ? 100 : 5}
            value={this.state.selectedStore.service}
            delta={this.state.selectedStore.serviceChange}
          />
          {isKia && (
            <SemiCircleProgress
              label="CSI"
              size={75}
              maxValue={isKia ? 100 : 5}
              value={this.state.selectedStore.csi}
              delta={""}
            />
          )}
        </Grid>
        {/* {isKia && (
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <SemiCircleProgress
              label="CSI (Combined Index)"
              size={isKia ? 115 : 150}
              maxValue={isKia ? 100 : 5}
              value={this.state.selectedStore.csi}
              delta={""}
            />
          </Grid>
        )} */}
      </>
    );
  }
}

export default withRouter(ChartView); // Wrap ChartView with withRouter to inject router props
