import {
  GET_STORES,
  GET_SINGLE_STORE,
  DELETE_STORE,
  UPDATE_STORE,
  CREATE_STORE,
} from "../ApiConstants";
import { api } from "../../utils";

class StoreApi {
  static sharedIstance = new StoreApi();

  constructor() {
    if (StoreApi.sharedIstance != null) {
      return StoreApi.sharedIstance;
    }
  }

  //  Get all stores  ...
  async getStores() {
    try {
      const response = await api.get(GET_STORES);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  //   Create Store  ..
  async createStore(body) {
    try {
      const response = await api.post(CREATE_STORE, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  //   Get single Store ..
  async getSingleStore(id) {
    try {
      const response = await api.get(`${GET_SINGLE_STORE}/${id}`);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  //   Delete store ..
  async deleteStore(id) {
    try {
      const response = await api.delete(`${DELETE_STORE}/${id}`);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  //   Update store ..
  async updateStore(body, id) {
    try {
      const response = await api.put(`${UPDATE_STORE}/${id}`, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
  async updateStoreScore(body, id) {
    try {
      const response = await api.put(`/stores/${id}/score`, body);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
}

export default StoreApi.sharedIstance;
