import * as React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
} from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import useMediaQuery from "@mui/material/useMediaQuery";

import {
  Navbar,
  SideBar,
  DashBoard,
  Stores,
  SignIn,
  ForbiddenAccess,
  PageNotFound,
  ChartView,
} from "../views";

import LocalStorage from "../managers/LocalStorage";
import { useAuth } from "../hooks/useAuth";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Routing = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = React.useState(false);

  const { isLoggedIn } = useAuth();

  // Protected Routes ..
  const AuthenticatedRoute = ({ children }) => {
    const isAuthenticated = LocalStorage.isLoggedIn();
    const location = useLocation();
    if (!isAuthenticated) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }

    return children;
  };

  // if user logged in then not navigate to login and singup page ..
  const Authenticated = ({ children }) => {
    const isAuthenticated = LocalStorage.isLoggedIn();
    const location = useLocation();
    if (isAuthenticated) {
      return <Navigate to="/dashboard" state={{ from: location }} replace />;
    }
    return children;
  };

  // Method for access routes based on the role ...
  const RoleAccess = () => {
    const user = LocalStorage.getUser();
    console.log("user");
    console.log(user);
    return user?.userPrincipalName ? (
      <Outlet />
    ) : (
      <Navigate to="/forbidden" replace />
    );
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (window.location.pathname.includes("chart/store/"))
    return (
      <Router>
        <CssBaseline />
        <Routes>
          <Route path="/chart/store/:store_id" element={<ChartView />} />
        </Routes>
      </Router>
    );

  return (
    <Router>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          {/* Top Bar  */}
          <Navbar
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            setOpen={setOpen}
          />
        </AppBar>
        {/* Left Drawer */}
        <SideBar
          setOpen={setOpen}
          open={open}
          handleDrawerClose={handleDrawerClose}
          theme={theme}
          isSmallScreen={isSmallScreen}
        />
        {/* Main Section  */}
        <Main open={open} sx={{ marginTop: "50px" }}>
          <Routes>
            <Route
              path="/"
              element={
                <Authenticated>
                  <SignIn />
                </Authenticated>
              }
            />

            <Route element={<RoleAccess roles={["Admin"]} />}>
              <Route path="/stores" element={<Stores />} />
            </Route>

            {/* Dashboard Route */}
            <Route element={<RoleAccess roles={["Admin"]} />}>
              <Route path="/dashboard" element={<DashBoard />} />
            </Route>

            <Route path="/forbidden" element={<ForbiddenAccess />} />
            {/* Page Not Found */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Main>
      </Box>
    </Router>
  );
};

export default Routing;
